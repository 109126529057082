<template>
    <ul>
        <li v-for="category in categories.categories" @click.self="$emit('update-category', category.slug)">
            {{ category.name }}
            <ul v-show="category.children !=null && expand==true && expandid == category.slug" class="dropdown">
                <li v-for="subcategory in category.children" @click="$emit('update-product', subcategory.slug)">
                    {{ subcategory.name }}
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
    export default {

        props: ["categories", "expand", "expandid"],

    }
</script>

<style lang="scss">
    @import "./src/styles/_global-vars.scss";

    #supplies-menu {

        ul {
            list-style: none;
            line-height: 2.3;
            width: 100%;
            padding-left: 0;
            text-align: center;
            li {
                font-size: 2.5rem;
                font-weight: 500;
                border-bottom: 2px solid #464646;
                cursor: pointer;

            }
        }
        .dropdown {
            list-style: none;
            line-height: 2.3;
            width: 100%;
            text-align: center;
            li {
                font-size: 1.75rem;
                border-bottom: 0;
                transition: color 500ms;
                &:hover {
                    color: $primary;
                }
            }
        }

    }

    @media screen and (max-width: 992px) {

        #supplies-menu {
            li {
                font-size: 1.5rem !important;
            }
            .dropdown {
                li {
                    font-size: 1rem !important;
                }
            }
        }

    }

</style>