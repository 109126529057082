<template>
    <div class="row sidebar" :style='{ backgroundImage: "url( " + disorder.image + ")", }' @click ="$emit('update-disorder', disorder.id )">
        <div class="inner text-center">
            <div class="col-12 py-5">
                <p>{{ disorder.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: ["disorder"],

        created() {},

        methods: {

        }

    }
</script>

<style lang="scss">
    @import "./src/styles/_global-vars.scss";

    .sidebar{
        background-size: cover;
        background-position: center;
        cursor: pointer;

        .inner{
            background: black;
            width: 100%;
            opacity: .6;
        }

        p{
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: 0;
            color: $white;
        }
    }
</style>