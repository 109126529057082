export default {
    init() {
        console.log('home');
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
        (function ($) {

            function animate_announcements() {
                const s2 = new TimelineMax();
                s2
                    .set('.news-container', {
                        autoAlpha: 0,
                        y: 20
                    })
                    .delay(1)
                    .staggerTo('.news-container', 1, {
                        autoAlpha: 1,
                        y: 0,
                        ease: Power2.easeIn
                    }, .5)
            }

            animate_announcements();

            $(function () {

                let weekday;

                $('#states .inner').on('click', function () {
                    $('#states .inner').removeClass('active');
                    $(this).addClass('active');
                    weekday = $(this).attr('id');
                    jQuery(".state .gfield_select").val(weekday);
                });


            });

        })(jQuery);
    },
};
