import disorderInfo from "../components/disorder-info.vue";
import disorderSidebar from "../components/disorder-sidebar.vue";
import jQuery from 'jquery';

export default {
    init() {
        console.log("Sleep Disorders");
    },
    finalize() {
        new Vue({
            components: {
                disorderInfo,
                disorderSidebar
            },

            el: "#whitney-app",

            data: {
                params: {},
                disorders: {},
                selected: null
            },

            mounted() {
                axios.get("/wp-json/cpl/v1/disorders").then(response => {
                    this.disorders = response.data;
                    this.selected = response.data[0].id;
                });
            },

            created() {

            },

            computed: {

            },

            methods: {
                updateOrder(id){
                    this.selected = id;
                }
            }
        });
    }
};