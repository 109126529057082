import jQuery from 'jquery';
import 'jquery-migrate';
import 'bootstrap';
import './style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import sleepSupplies from './routes/sleepSupplies';
import sleepDisorders from './routes/sleepDisorders';
import providers from './routes/providers';
import ourLocations from './routes/ourLocations';
import center from './routes/center';

import WebFont from 'webfontloader';

import 'owl.carousel';

import { TweenMax, TimelineLite, Power2, Elastic, CSSPlugin } from 'gsap/TweenMax'

import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faSortDown } from '@fortawesome/pro-solid-svg-icons'
// import { far } from '@fortawesome/pro-regular-svg-icons'
import { faLongArrowRight, faCalendar, faTimesHexagon , faBars, faCircle, faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'


// Add all icons to the library so you can use it in your page
library.add(faLongArrowRight, faCalendar, faFacebookSquare, faTimesHexagon, faCircle, faBars, faArrowRight, faSortDown);

import Promise from "es6-promise";

require("es6-promise").polyfill();

import axios from "axios";

window.axios = axios;

// Vue Stuff
import Vue from "vue/dist/vue.js";

window.Event = new Vue();

window.Vue = Vue;

dom.i2svg();

dom.watch({
    autoReplaceSvgRoot: document,
    observeMutationsRoot: document.body
});

// Initialize webfonts
WebFont.load({
    google: {
        families: ['Droid Sans', 'Source Sans Pro']
    },
    custom: {
        families: ['Freight Sans', 'Sloop Script One'],
        urls: ['/wp-content/themes/Capabl/fonts/custom-fonts.css']
    }
});

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home,
    /** About Us page, note the change from about-us to aboutUs. */
    sleepSupplies,
    sleepDisorders,
    providers,
    ourLocations,
    center,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());