export default {
    init() {
        console.log('Staff at Whitney Sleep Center');
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS

        function animate_staff() {
            const s2 = new TimelineMax();
            s2
                .set('.staff-container', {
                    autoAlpha: 0,
                    y: 20
                })
                .delay(1)
                .staggerTo('.staff-container', 1, {
                    autoAlpha: 1,
                    y: 0,
                    ease: Power2.easeIn
                }, .5)
        }

        animate_staff();
    },
};
