<template>
    <div class="supply-info">
        <div class="inner py-4 px-3" v-for="supply in supplies"
             v-show="supply.brandSlug === clicked && hidden == false && hiddenid == supply.brandSlug">
            <div class="row">
                <div class="col-sm-7">
                    <p class="title m-0" v-html="supply.brand" :id="supply.category">{{ supply.brand }}</p>
                    <p class="brand" v-html="supply.name">{{ supply.name }}</p>
                    <p class="slug" v-html="supply.description">{{ supply.description }}</p>
                    <ul v-if="supply.details !=''">
                        <li v-for="detail in supply.details">{{ detail }}</li>
                    </ul>
                    <div class="text-center pt-4">
                        <button type="button" class="btn cpl-arrow-btn" data-toggle="modal"
                                data-target="#equipmentModal" :data-id="supply.name">Request Equipment
                        </button>
                    </div>
                    <div class="text-center pt-4">
                        <i class="fas fa-sort-down"></i> <small>Scroll to See More</small>
                    </div>
                </div>
                <div class="col-sm-5">
                    <img :src="supply.img" :alt="supply.name" width="100%">
                </div>
            </div>
            <div class="col-sm-12 text-center py-4">
                <div id="divider"></div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {

        props: ["supplies", "clicked", "hidden", "hiddenid"],
    }
</script>

<style lang="scss">
    @import "./src/styles/_global-vars.scss";

    #supplies-inner {
        background: $body-gray;
        overflow: scroll;
    }

    #supplies-info {
        max-height: 570px;
        overflow: scroll;
    }

    #supplies-menu {
        background: $white;
    }

    .title {
        font-size: 2rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    p#accessories {
        display: none;
    }

    .brand {
        font-size: 1.5rem;
    }

    #divider {
        background: $main-gray;
        height: 2px;
        width: 70%;
        margin: auto;
        margin-top: 3rem;
    }

</style>