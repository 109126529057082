<template>
    <div class="row h-100 align-items-center disorder">
        <div class="col-12 p-5" v-for="disorder in disorders" v-if="disorder.id === selected">
            <h2 class="text-center pb-3">{{ disorder.name }}</h2>
            <p v-html="disorder.description">{{ disorder.description }}</p>
        </div>
    </div>
</template>

<script>
    export default {

        props: ["disorders", "selected"]

    }
</script>

<style lang="scss">
    @import "./src/styles/_global-vars.scss";

    @media (max-width: 575px) {
        .disorder > .col-12 {
            padding: 2rem .75rem !important;
        }
    }

</style>