import categorySidebar from "../components/category-sidebar.vue";
import suppliesInfo from "../components/supplies-info.vue";
import categoryInfo from "../components/category-info.vue";
import VueRouter from "vue-router";

export default {
    init() {
        console.log("Find a sleep supply");
    },
    finalize() {
        Vue.use(VueRouter);

        const router = new VueRouter({
            mode: "history",
            routes: [
                { path: "/sleep-supplies/", component: categoryInfo },
                { path: "/sleep-supplies/:id", component: categoryInfo }
            ]
        });

        new Vue({
            router: router,
            components: {
                categorySidebar,
                suppliesInfo,
                categoryInfo
            },

            el: "#whitney-app",

            data: {
                routing: {
                    history: 0
                },
                params: {},
                supplies: {},
                categories: {},
                selected: {},
                clicked: {},
                hide: false,
                hidden: true,
                expand: false,
                expandID: {},
                hiddenID: {},
            },

            mounted() {

                axios.get("/wp-json/cpl/v1/supplies").then(response => {
                    this.supplies = response.data;
                    this.selected = "nasal-p";
                });

                axios.get("/wp-json/cpl/v1/supplies/categories").then(response => {
                    let subcategories;
                    this.categories = response.data;
                    this.categories.categories.forEach((category) => {
                        subcategories = category.children;
                        subcategories.forEach((subcategory) => {
                            this.clicked = subcategory.slug;
                        });
                    });
                });

                this.updateQuery();
                this.checkPath();

                window.addEventListener("load", () => {
                    if (this.$route.fullPath.includes("categorySlug")) {
                    }
                });
            },

            created() {
                Event.$on("updateCategory", this.updateCategory);
            },

            computed: {},

            methods: {

                updateURL(slug) {
                    slug = this.selected;
                    this.$router.push({path: `?category=${slug}`});
                },

                updateCategory(slug) {
                    this.hide = false;
                    this.hidden = true;
                    this.selected = slug;
                    this.expandID = slug;

                    if (this.expand == false) {
                        if (this.expandID == slug) {
                            this.expand = true;
                        }
                    } else {
                        if (this.expandID == slug) {
                            this.expand = true;
                        } else {
                            this.expand = false;
                        }
                    }
                    this.updateURL(this.selected);

                },
                updateProduct(slug) {
                    this.clicked = slug;
                    this.hide = true;
                    this.hiddenID = slug;

                    if (this.hidden == true) {
                        if (this.hiddenID == slug) {
                            this.hidden = false
                        }
                    }

                },
                route: function (params) {
                    if (this.routing.history === 0) {

                        if (this.$route.query !== null) {

                            if(this.$route.fullPath=='/sleep-supplies/'){
                                this.selected = 'nasal-p';

                            }else{
                                params = this.$route.query;
                                axios.get("/wp-json/cpl/v1/supplies").then(response => {
                                    this.supplies = response.data;
                                    this.selected = params.category;
                                });
                            }

                        } else {
                            this.selected = "nasal-p";
                        }
                        this.routing.history++;
                    } else {

                        this.$router.push({
                            path: "/sleep-supplies",
                            query: {categoryID: params}
                        }).catch(err => {});
                    }

                    let query = JSON.parse(JSON.stringify(params));
                    this.params = query;
                },
                updateQuery() {
                    let params = this.params;

                    axios.get("/wp-json/cpl/v1/supplies").then(response => {
                        this.supplies = response.data;
                        this.selected = params;
                        this.route(params);
                    });
                },

                checkPath(){
                    if(this.$route.fullPath.includes('category=')){
                        this.expandID = this.$route.query.category;
                        this.expand = true;
                    }
                },

            }
        });

        (function ($) {
            "use strict";

            $(function () {
                let id;
                $('#supplies-info .supply-info').on('click', '.btn.cpl-arrow-btn', function () {
                    id = $(this).data('id');
                    jQuery("#input_4_4").val(id);
                })

            });

        })(jQuery);
    }
};
