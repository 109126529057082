<template>
    <div class="row align-items-center category anime-1">
        <div class="col-12 p-5" v-for="category in categories.categories"
             v-if="category.slug === selected && hide==false">
            <h2 class="text-center pb-3"><strong>{{ category.name }}</strong></h2>
            <p class="px-4">{{ category.description }}</p>
        </div>
    </div>
</template>

<script>

    export default {

        props: ["categories", "selected", "hide"],

    }
</script>

<style lang="scss">
    @import "./src/styles/_global-vars.scss";

    .title {
        font-size: 2rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    .brand {
        font-size: 1.5rem;
    }

</style>