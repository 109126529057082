export default {
    init() {
        console.log("Find a site location");
    },
    finalize() {

        (function ($) {
            "use strict";

            $(function () {

                $(window).load(function () {

                    hideCalloutBoxes();

                    if (document.location.href.indexOf('#south-dakota') > -1) {
                        $.ajax({
                            url: "/wp-json/cpl/v1/sites?state=us_41",
                            success: function (data) {

                                var site;
                                var html = ' ';

                                for (var i = 0; i < data.length; i++) {
                                    site = data[i];

                                    if (i < 1) {
                                        html = html + '<div class="col-12"><h3>' + site.state + '</h3></div>';
                                    }

                                    html = html + '<div class="col-lg-3 col-md-6 site-container py-4" data-id="' + site.id + '" data-toggle="modal" data-target="#mapModal"><div class="inner h-100 text-center">';

                                    html = html + '<div class="title-container p-2">' + site.city + '</div>';

                                    html = html + '<div class="address-container p-4"><p class="site-name">' + site.name + '</p><p class="m-0">' + site.address + '</p></div>';

                                    html = html + '</div></div>';

                                }

                                $('#async-container').html(html);

                            }
                        });
                    } else if (document.location.href.indexOf('#north-dakota') > -1) {
                        $.ajax({
                            url: "/wp-json/cpl/v1/sites?state=us_34",
                            success: function (data) {

                                var site;
                                var html = ' ';

                                for (var i = 0; i < data.length; i++) {
                                    site = data[i];

                                    if (i < 1) {
                                        html = html + '<div class="col-12"><h3>' + site.state + '</h3></div>';
                                    }

                                    html = html + '<div class="col-lg-3 col-md-6 site-container py-4" data-id="' + site.id + '" data-toggle="modal" data-target="#mapModal"><div class="inner h-100 text-center">';

                                    html = html + '<div class="title-container p-2">' + site.city + '</div>';

                                    html = html + '<div class="address-container p-4"><p class="site-name">' + site.name + '</p><p class="m-0">' + site.address + '</p></div>';

                                    html = html + '</div></div>';

                                }

                                $('#async-container').html(html);
                            }
                        });

                    } else if (document.location.href.indexOf('#wisconsin') > -1) {
                        $.ajax({
                            url: "/wp-json/cpl/v1/sites?state=us_49",
                            success: function (data) {

                                var site;
                                var html = ' ';

                                for (var i = 0; i < data.length; i++) {
                                    site = data[i];

                                    if (i < 1) {
                                        html = html + '<div class="col-12"><h3>' + site.state + '</h3></div>';
                                    }

                                    html = html + '<div class="col-lg-3 col-md-6 site-container py-4" data-id="' + site.id + '" data-toggle="modal" data-target="#mapModal"><div class="inner h-100 text-center">';

                                    html = html + '<div class="title-container p-2">' + site.city + '</div>';

                                    html = html + '<div class="address-container p-4"><p class="site-name">' + site.name + '</p><p class="m-0">' + site.address + '</p></div>';

                                    html = html + '</div></div>';

                                }

                                $('#async-container').html(html);

                            }
                        });
                    } else if (document.location.href.indexOf('#iowa') > -1) {
                        $.ajax({
                            url: "/wp-json/cpl/v1/sites?state=us_15",
                            success: function (data) {

                                var site;
                                var html = ' ';

                                for (var i = 0; i < data.length; i++) {
                                    site = data[i];

                                    if (i < 1) {
                                        html = html + '<div class="col-12"><h3>' + site.state + '</h3></div>';
                                    }

                                    html = html + '<div class="col-lg-3 col-md-6 site-container py-4" data-id="' + site.id + '" data-toggle="modal" data-target="#mapModal"><div class="inner h-100 text-center">';

                                    html = html + '<div class="title-container p-2">' + site.city + '</div>';

                                    html = html + '<div class="address-container p-4"><p class="site-name">' + site.name + '</p><p class="m-0">' + site.address + '</p></div>';

                                    html = html + '</div></div>';

                                }

                                $('#async-container').html(html);
                            }
                        });
                    } else if (document.location.href.indexOf('#nebraska') > -1) {
                        $.ajax({
                            url: "/wp-json/cpl/v1/sites?state=us_27",
                            success: function (data) {

                                var site;
                                var html = ' ';

                                for (var i = 0; i < data.length; i++) {
                                    site = data[i];

                                    if (i < 1) {
                                        html = html + '<div class="col-12"><h3>' + site.state + '</h3></div>';
                                    }

                                    html = html + '<div class="col-lg-3 col-md-6 site-container py-4" data-id="' + site.id + '" data-toggle="modal" data-target="#mapModal"><div class="inner h-100 text-center">';

                                    html = html + '<div class="title-container p-2">' + site.city + '</div>';

                                    html = html + '<div class="address-container p-4"><p class="site-name">' + site.name + '</p><p class="m-0">' + site.address + '</p></div>';

                                    html = html + '</div></div>';

                                }

                                $('#async-container').html(html);
                            }
                        });
                    } else {
                        $.ajax({
                            url: "/wp-json/cpl/v1/sites?state=us_23",
                            success: function (data) {

                                var site;
                                var html = ' ';

                                for (var i = 0; i < data.length; i++) {
                                    site = data[i];

                                    if (i < 1) {
                                        html = html + '<div class="col-12"><h3>' + site.state + '</h3></div>';
                                    }

                                    html = html + '<div class="col-lg-3 col-md-6 site-container py-4" data-id="' + site.id + '" data-toggle="modal" data-target="#mapModal"><div class="inner h-100 text-center">';

                                    html = html + '<div class="title-container p-2">' + site.city + '</div>';

                                    html = html + '<div class="address-container p-4"><p class="site-name">' + site.name + '</p><p class="m-0">' + site.address + '</p></div>';

                                    html = html + '</div></div>';

                                }

                                $('#async-container').html(html);
                            }
                        });
                    }
                });


                $("#visnames text").on("click", function (e) {
                    e.preventDefault();
                    $('.map-container').css('display', 'none');
                    $('#async-container').html('');

                    hideCalloutBoxes();

                    var state = $(this).attr('id');
                    var id = state.substring(0, 3) + state.substr(5, 7);
                    var permalink = $(this).attr('href');

                    $.ajax({
                        url: "/wp-json/cpl/v1/sites?state=" + id,
                        success: function (data) {

                            window.history.pushState({}, "", permalink);
                            var site;
                            var html = ' ';

                            for (var i = 0; i < data.length; i++) {
                                site = data[i];

                                if (i < 1) {
                                    html = html + '<div class="col-12"><h3>' + site.state + '</h3></div>';
                                }

                                html = html + '<div class="col-lg-3 col-md-6 site-container py-4" data-id="' + site.id + '" data-toggle="modal" data-target="#mapModal"><div class="inner h-100 text-center">';

                                html = html + '<div class="title-container p-2">' + site.city + '</div>';

                                html = html + '<div class="address-container p-4"><p class="site-name">' + site.name + '</p><p class="m-0">' + site.address + '</p></div>';

                                html = html + '</div></div>';

                            }

                            $('#async-container').html(html);
                        }
                    });

                }); //End function


                //Change when clicked on footer links
                $("#footer-location .menu .menu-item").on("click", function (e) {
                    $('#async-container').html('');

                    hideCalloutBoxes();

                    var state = $(this).attr('class');
                    var id = state.split(" ")[0];
                    // var permalink = $(this).attr('href');

                    $.ajax({
                        url: "/wp-json/cpl/v1/sites?state=" + id,
                        success: function (data) {

                            // window.history.pushState({}, "", permalink);
                            var site;
                            var html = ' ';

                            for (var i = 0; i < data.length; i++) {
                                site = data[i];

                                if (i < 1) {
                                    html = html + '<div class="col-12"><h3>' + site.state + '</h3></div>';
                                }

                                html = html + '<div class="col-lg-3 col-md-6 site-container py-4" data-id="' + site.id + '" data-toggle="modal" data-target="#mapModal"><div class="inner h-100 text-center">';

                                html = html + '<div class="title-container p-2">' + site.city + '</div>';

                                html = html + '<div class="address-container p-4"><p class="site-name">' + site.name + '</p><p class="m-0">' + site.address + '</p></div>';

                                html = html + '</div></div>';

                            }

                            $('#async-container').html(html);
                        }
                    });

                }); //End function

                $("#map path").on("click", function (e) {
                    e.preventDefault();
                    $('.map-container').css('display', 'none');
                    $('#async-container').html('');

                    hideCalloutBoxes();

                    var state = $(this).attr('id');
                    console.log(state);
                    var permalink = $(this).attr('href');

                    $.ajax({
                        url: "/wp-json/cpl/v1/sites?state=" + state,
                        success: function (data) {

                            window.history.pushState({}, "", permalink);

                            var site;
                            var html = ' ';

                            for (var i = 0; i < data.length; i++) {
                                site = data[i];

                                if (i < 1) {
                                    html = html + '<div class="col-12"><h3>' + site.state + '</h3></div>';
                                }

                                html = html + '<div class="col-lg-3 col-md-6 site-container py-4" data-id="' + site.id + '" data-toggle="modal" data-target="#mapModal"><div class="inner h-100 text-center">';

                                html = html + '<div class="title-container p-2">' + site.city + '</div>';

                                html = html + '<div class="address-container p-4"><p class="site-name">' + site.name + '</p><p class="m-0">' + site.address + '</p></div>';

                                html = html + '</div></div>';

                            }

                            $('#async-container').html(html);
                        }
                    });

                }); //End function

                $('#async-container').on('click', '.site-container', function () {

                    $('.site-container').removeClass('highlight');
                    $(this).addClass('highlight');

                    var id = $(this).data('id');
                    $('.map-container').each(function () {

                        var map_id = $(this).attr('id')
                        if (map_id == id) {
                            $('.map-container').css('display', 'none');
                            $(".map-container#" + id).css('display', 'flex');
                        }
                    });
                });
            });

            function hideCalloutBoxes() {
                $('g:last-of-type').find('rect').attr('fill', '#fff');
                $('g:last-of-type').find('rect:first').attr('y', '148');
                $('g:last-of-type').find('rect:nth-of-type(2)').attr('y', '39');
                $('g:last-of-type').find('rect:last').attr('y', '400');
            }

        })(jQuery);

    }
};
